<template>
    <div class="modal secondary security card-form" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header">
                <h1 class="sub_header m-0">Card Details</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="setting_wpr">
                <div v-if="setupIntentLoader" class="card-loader">
                    <quote-loader />
                </div>
                <Form @submit="handleCardAdd" v-slot="{ errors }" class="profile-form" v-else>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Name on Card</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                <Field type="text" autocomplete="off" name="name" v-model="cardForm.name" rules="required" placeholder="Enter Cardholder Name" />
                            </div>
                            <ErrorMessage name="name" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Card Number</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.card_number }">
                                <Field autocomplete="off" type="text" name="card_number" v-model="cardForm.card_number">
                                    <div id="card-element"></div>
                                </Field>
                            </div>
                            <ErrorMessage name="card_number" class="text-danger" />
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="addCardLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button v-if="companyUserCan('update', 'checkouts')" :disabled="addCardLoader" class="btn save_btn pointer">
                            <i class="fa fa-spinner fa-spin" v-if="addCardLoader"></i>
                            {{ addCardLoader ? 'Adding' : 'Add' }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        data () {
            return {
                cardForm: {},
                setupIntent: null,
                card: null,
                stripeInstance: null,
            };
        },

        props: {
            modelValue: Boolean,
            client: Object,
            processor: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                vm.stripeInstance = window.Stripe(vm.processor.publishable_key, {});

                if (!val && vm.card && !vm.card._destroyed) {
                    vm.card.destroy();
                    vm.card = null;
                }

                if (val) {
                    vm.createSetupIntent({ client: vm.client.id, processor: vm.processor.id }).then((setupIntent) => {
                        vm.setupIntent = setupIntent;

                        setTimeout(function () {
                            vm.initializeStripe();
                        }, 10);
                    });
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                addCardLoader: state => state.checkoutModule.addCardLoader,
                setupIntentLoader: state => state.checkoutModule.setupIntentLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),

            stripeElements () {
                return this.stripeInstance.elements();
            },
        },

        beforeUnmount () {
            const vm = this;

            if (vm.card && !vm.card._destroyed) {
                vm.card.destroy();
            }
        },

        methods: {
            ...mapActions({
                createSetupIntent: 'checkoutModule/createSetupIntent',
                updateClientCard: 'checkoutModule/updateClientCard',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            async handleCardAdd (form, { setFieldError }) {
                const vm = this;

                const { setupIntent, error } = await vm.stripeInstance.confirmCardSetup(
                    vm.setupIntent.client_secret, {
                        payment_method: {
                            card: vm.card,
                            billing_details: { name: form.name }
                        }
                    }
                );

                if (error) {
                    setFieldError('card_number', error.message);
                } else {
                    vm.updateClientCard({ client_id: vm.client.id, intent: setupIntent, processor: vm.processor.id }).then((resp) => {
                        if (resp) {
                            if (vm.card && !vm.card._destroyed) {
                                vm.card.destroy();
                                vm.$emit('update:modelValue', false);
                            }
                        }
                    })
                }
            },

            initializeStripe () {
                const vm = this;

                const style = {
                    base: {
                        color: '#5a5a5a',
                        lineHeight: '50px',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#eb1414',
                        iconColor: '#eb1414',
                    },
                };

                vm.card = vm.stripeElements.create('card', { hidePostalCode: true, style });
                vm.card.mount('#card-element');
            },
        },
    }
</script>

<style scoped>
    .profile-form .action_wpr {
        border-top: 0px solid #eaeaea !important;
        margin: 20px 0 0 !important;
        padding: 0px !important;
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }

    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .card-loader {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .setting_wpr {
        width: 100%;
    }

    .close_btn {
        left: auto;
    }
</style>
